import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  background-color: #ccc;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9998;
  opacity: 0.5;
`;

export const Container = styled.div`
  position: fixed;
  width: 100%;
  max-width: 565px;
  min-height: 300px;
  z-index: 9999;
  top: 25px;
  left: calc(50% - 282px);
  background-color: #ffffff;
  box-shadow: 0px 0px 6px #0000001a;
  border-radius: 10px;
  padding: 25px 25px 25px 25px;
  max-height: 790px;
  height: 100%;
  overflow: auto;

  @media (max-width: 425px) {
    max-width: 400px;
    left: calc(50% - 200px);
    top: 2vh;
    overflow-y: scroll;
    height: 96vh;
  }

  @media (max-width: 375px) {
    max-width: 350px;
    left: calc(50% - 175px);
  }
  @media (max-width: 320px) {
    max-width: 300px;
    left: calc(50% - 150px);
  }
`;

export const Logo = styled.img`
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 116px;
  display: block;
  margin-bottom: 20px;
`;

export const Body = styled.div`
  font-size: 18px;
  position: relative;

  & h3 {
    font: normal normal bold 22px/36px Utopia Std;
    margin-top: 0px;
    margin-bottom: 0px;
    @media (max-width: 425px) {
      font-size: 16px;
    }
  }
  & p {
    color: #001c72;
    font-size: 18px;
    margin-top: 0;
    @media (max-width: 425px) {
      font-size: 14px;
      margin-top: 10px;
    }
  }
  & ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 10px;
    margin-bottom: 20px;
    & > li {
      padding-bottom: 30px;
      font-size: 18px;
      padding-left: 30px;
      position: relative;
      border-left: 1px solid #001c72;
      padding-top: 0;
      margin: 0;

      @media (max-width: 425px) {
        font-size: 16px;
        padding-left: 20px;
      }

      &:last-child {
        padding-bottom: 0;
        border-left: none;
      }

      &:before {
        content: ' ';
        width: 15px;
        height: 15px;
        background-color: #bc2e3e;
        border-radius: 100%;
        position: absolute;
        left: -8px;
        top: 0;
      }

      & .klarna-logo {
        background-image: url('/images/klarna.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 59px;
        height: 33px;
        display: inline-block;
        position: absolute;
        margin-left: 10px;
        margin-top: -10px;
      }
    }
  }
  & p.gray {
    color: #767676;
    font-size: 16px;
    margin-top: 20px;

    @media (max-width: 425px) {
      font-size: 14px;
      text-align: center;
    }

    &.text-center {
      text-align: center;
      & a {
        margin: 0 20px 0 17px;
        text-align: center;
        @media (max-width: 425px) {
          margin: 0 3px 0 0px;
          font-size: 14px;
        }
      }
    }

    & > a {
      color: #767676;
      font-size: 16px;
      text-decoration: underline;

      @media (max-width: 425px) {
        font-size: 14px;
      }
    }
  }

  & > button {
    background-color: #001c72;
    box-shadow: 0 4px 0 #0a1a4a;

    &.processing {
      background-color: #0a1a4a;
    }
  }
  & > button:hover {
    background-color: #0a1a4a;
  }
`;

export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  background-color: #fff;
  color: #001c72;
  border-radius: 50px;
  text-align: center;
  padding-top: 17px;
  padding-left: 1px;
  position: absolute;
  line-height: 0;
  right: 15px;
  top: 15px;
  cursor: pointer;
  font-size: 30px;
  line-height: 0.2;
  font-weight: bold;
`;
